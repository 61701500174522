<template>
  <section>
 
    
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              Ajouter Un client
              <router-link to="/getclients">
                <span class="float-right btn btn-info"> retour </span>
              </router-link>
            </div>
            <div class="container">
                 <div class="row">
      
          <div class="row">
       <GmapAutocomplete class="form-control" @place_changed="setPlace" />
        <button  style=" margin-left: 10px;
  padding: 10px 20px;
  background-color: rgb(158, 228, 215);" class="btn" @click="addMarker">Ajouter</button>
     </div>
   

    <GmapMap
      ref="map"
      :center="center"
      :zoom="zoom"
      style="width: 100%; height: 400px; margin-top: 20px"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="center = m.position"
      />
    </GmapMap>

    </div>
              <form class="forms-sample" @submit.prevent="addclient()">
                <div v-if="errors.length">
                  <ul>
                    <li
                      class="invalid-feedback"
                      v-for="error in errors"
                      :key="error"
                    >
                      - {{ error }}
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputName1"
                        >Nom <span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="name"
                        type="text"
                        class="form-control"
                        id="name"
                        placeholder="name"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputEmail3"
                        >Adresse Email<span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="email"
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="Email"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword4"
                        >Télephone<span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="phone"
                        type="text"
                        class="form-control"
                        id="phone"
                        placeholder="phone"
                        required
                      />
                    </div>
                  </div>
                    <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword4"
                        >RNE<span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="rne"
                        type="text"
                        class="form-control"
                        id="phone"
                        placeholder="rne"
                        required
                      />
                    </div>
                     
                  </div>
                   <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword4"
                        >Reference<span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="reference"
                        type="text"
                        class="form-control"
                        id="phone"
                        placeholder="Reference"
                        required
                      />
                    </div>
                     </div>
                      <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword4"
                        >Patente<span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="patente"
                        type="text"
                        class="form-control"
                        id="phone"
                        placeholder="Patente"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputCity1"
                        >Adresse<span class="obligatoire">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="adresse"
                        v-model="adresse"
                        placeholder="adresse"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputEmail3"
                        >Lattitude<span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="latitude"
                        
                        class="form-control"
                        id="lattitude"
                        placeholder="lattitude"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword4"
                        >Longitude<span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="longitude"
                        
                        class="form-control"
                        id="longitude"
                        placeholder="longitude"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleSelectGender"
                        >Type<span class="obligatoire">*</span></label
                      >
                      <select
                        class="form-control"
                        id="categorie"
                        v-model="categorie"
                        required
                        @change="onSelectChange(categorie)"
                      >
                        <option value="Particulier">Particulier</option>
                        <option value="Entreprise">Entreprise</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4" id="registre" style="display: none">
                    <div class="form-group">
                      <label for="exampleInputCity1"
                        >Registre de commerce<span class="obligatoire"
                          >*</span
                        ></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="registre"
                        v-model="registre"
                        placeholder="registre"
                      />
                    </div>
                  </div>

                  <div
                    class="col-md-4"
                    id="mode_exercice"
                    style="display: none"
                  >
                    <div class="form-group">
                      <label for="exampleSelectGender"
                        >Mode Exercice<span class="obligatoire">*</span></label
                      >
                      <select
                        class="form-control"
                        id="mode_exercice"
                        v-model="mode_exercice"
                      >
                        <option>Médecin santé publique</option>
                        <option>Médecin libre pratique</option>
                        <option>Médecin hospitalo universitaire</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-4" id="specialite" style="display: none">
                    <div class="form-group">
                      <label for="exampleSelectGender"
                        >Specialité<span class="obligatoire">*</span></label
                      >
                      <select class="form-control" v-model="specialite">
                        <option>Anatomie</option>
                        <option>Anatomie et cytologie pathologique</option>
                        <option>Anesthésie réanimation</option>
                        <option>Biologie médicale</option>
                        <option>Biologie médicale option biochimie</option>
                        <option>Biologie médicale option hématologie</option>
                        <option>Biologie médicale option immunologie</option>
                        <option>Biologie médicale option microbiologie</option>
                        <option>Biologie médicale option parasitologie</option>
                        <option>Biophysique et médecine nucléaire</option>
                        <option>Carcinologie médicale</option>
                        <option>Cardiologie</option>
                        <option>Chirurgie carcinologique</option>
                        <option>Chirurgie cardio vasculaire</option>
                        <option>Chirurgie générale</option>
                        <option>Chirurgie neurologique</option>
                        <option>
                          Chirurgie orthopédique et traumatologique
                        </option>
                        <option>Chirurgie pédiatrique</option>
                        <option>
                          Chirurgie plastique réparatrice et esthétique
                        </option>
                        <option>Chirurgie thoracique</option>
                        <option>Chirurgie urologique</option>
                        <option>Chirurgie vasculaire périphérique</option>
                        <option>Dermatologie</option>
                        <option>
                          Direction Et Logistique Médico-militaire
                        </option>
                        <option>Endocrinologie</option>
                        <option>Gastro-entérologie</option>
                        <option>Génétique</option>
                        <option>Gynécologie obstétrique</option>
                        <option>Hématologie clinique</option>
                        <option>Histo-embryologie</option>
                        <option>Hygiène Nucléaire</option>
                        <option>Imagerie médicale</option>
                        <option>Maladies infectieuses</option>
                        <option>Médecine aéronautique et spatiale</option>
                        <option>Médecine de Famille</option>
                        <option>Médecine De La Plongée Sous-marine</option>
                        <option>Médecine du travail</option>
                        <option>Médecine d'urgence</option>
                        <option>Médecine générale</option>
                        <option>Médecine interne</option>
                        <option>Médecine légale</option>
                        <option>Médecine nucléaire</option>
                        <option>
                          Médecine physique, rééducation et réadaptation
                          fonctionnelle
                        </option>
                        <option>Médecine préventive et communautaire</option>
                        <option>Néphrologie</option>
                        <option>Neurologie</option>
                        <option>Nutrition et maladies nutritionnelles</option>
                        <option>Ophtalmologie</option>
                        <option>Oto-rhino-laryngologie</option>
                        <option>Pédiatrie</option>
                        <option>Pédo psychiatrie</option>
                        <option>Pharmacologie</option>
                        <option>
                          Physiologie et exploration fonctionnelle
                        </option>
                        <option>Pneumologie</option>
                        <option>Psychiatrie</option>
                        <option>Radiothérapie carcinologique</option>
                        <option>Réanimation médicale</option>
                        <option>Rhumatologie</option>
                        <option>sans spécialité</option>
                        <option>Spécialiste en médecine de famille</option>
                        <option>
                          Stomatologie et chirurgie maxillo-faciale
                        </option>
                        <option>Urologie</option>
                      </select>
                    </div>
                  </div>

                  <div
                    class="col-md-4"
                    id="matricule"
                    style="display: none; clear: none"
                  >
                    <div class="form-group">
                      <label for="exampleInputCity1"
                        >Matricule Fiscale<span class="obligatoire"
                          >*</span
                        ></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="matricule"
                        placeholder="matricule fiscale"
                      />
                    </div>
                  </div>

                  <div
                    class="col-md-4"
                    id="delegation"
                    style="display: none; clear: none"
                  >
                    <div class="form-group">
                      <label for="exampleInputCity1"
                        >Délégation<span class="obligatoire">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="delegation"
                        placeholder="delegation"
                      />
                    </div>
                  </div>
                  <div
                    class="col-md-4"
                    id="gouvernerat"
                    style="display: none; clear: none"
                  >
                    <div class="form-group">
                      <label for="exampleSelectGender"
                        >Gouvernerat<span class="obligatoire">*</span></label
                      >
                      <select class="form-control" v-model="gouvernerat">
                        <option>Ariana</option>
                        <option>Béja</option>
                        <option>Ben Arous</option>
                        <option>Bizerte</option>
                        <option>Gabès</option>
                        <option>Gafsa</option>
                        <option>Jendouba</option>
                        <option>Kairouan</option>
                        <option>Kasserine</option>
                        <option>Kébili</option>
                        <option>Le Kef</option>
                        <option>Mahdia</option>
                        <option>La Manouba</option>
                        <option>Médenine</option>
                        <option>Monastir</option>
                        <option>Nabeul</option>
                        <option>Sfax</option>
                        <option>Sidi Bouzid</option>
                        <option>Siliana</option>
                        <option>Sousse</option>
                        <option>Tataouine</option>
                        <option>Tozeur</option>
                        <option>Tunis</option>
                        <option>Zaghouane</option>
                      </select>
                    </div>
                  </div>
                  <!-- <div class="form-group">
            <label for="formFile" class="form-label">Matricule</label>
            <input class="file-upload-default" type="file" />
            <div class="input-group col-xs-8">
              <input
                type="file"
                class="form-control file-upload-info"
                placeholder="Upload Image"
                @change="onFileChange()"
                ref="file"
              /> -->
                  <!-- 
              <span class="input-group-append">
                <button
                  class="file-upload-browse btn btn-gradient-primary"
                  type="button"
                >
                  Télécharger
                </button>
              </span>
            </div>
          </div> -->
                </div>

                <br />

                <button type="submit" class="btn btn-gradient-primary mr-2">
                  Ajouter
                </button>
                <router-link  to="/getclients" class="btn btn-light">Annuler</router-link>
              </form>
            </div>
          </div>
        </div>
      </div>
    
    <!-- </div> -->
  </section>
</template>
 
<script>
import { HTTP } from "@/axios";

export default {
  name: "addclient",
  data() {
    return {
       zoom:9,
      center: { lat: 36.794895, lng: 10.170715 },
      currentPlace: null,
      markers: [],
      errors: [],
      name: "",
      phone: "",
      adresse: "",
      categorie: "",
      email: "",
      specialite: "",
      mode_exercice: "",
      delegation: "",
      longitude: "",
      latitude: "",
      registre: "",
      gouvernerat: "",
      matricule: "",
      patente:"",
      rne:"",
      reference:"",
    };
  },
    mounted() {
    this.locateGeoLocation();
    $('.pac-target').attr('placeholder','Some New Text 1');
  },
  methods: {
      setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
       
         this.name=this.currentPlace.name
         this.adresse=this.currentPlace.formatted_address
         this.phone=this.currentPlace.formatted_phone_number
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
      
        this.markers.push({ position: marker });
        
        this.center = marker;
        
          this.zoom=15;
        this.currentPlace = null;
         this.longitude= marker.lng;
         this.latitude=marker.lat;
         
      }
    },
      locateGeoLocation: function() {
      navigator.geolocation.getCurrentPosition(res => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude
        };
      });
    },
    addclient() {
      if (this.categorie == "Particulier") {
        
          this.name &&
          this.phone &&
          this.adresse &&
          this.categorie &&
          this.email &&
          this.delegation &&
          this.gouvernerat &&
          this.latitude &&
          this.matricule &&
          this.longitude;
          
      } else  {
          this.name &&
          this.phone &&
          this.adresse &&
          this.categorie &&
          this.email &&
          this.specialite &&
          this.mode_exercice &&
          this.latitude &&
          this.registre &&
          this.longitude &&
          this.latitude &&
           this.delegation &&
          this.gouvernerat 
      } ;
      {
        let client = {
          email: this.email,
          phone: this.phone,
          name: this.name,
          adresse: this.adresse,
          categorie: this.categorie,
          delegation: this.delegation,
          gouvernerat: this.gouvernerat,
          mode_exercice: this.mode_exercice,
          specialite: this.specialite,
          latitude: this.latitude,
          longitude: this.longitude,
          registre: this.registre,
          matricule: this.matricule,
          rne: this.rne,
          patente: this.patente,
         reference: this.reference
        };

        HTTP.post("clients/addclient", client).then((response) => {
          console.log(response.data);

          Toast.fire({
            icon: "success",
            title: "client ajouté",
          });
          this.$router.push("/getclients");
        });
      }
      this.errors = [];

      if (!this.email) {
        this.errors.push("email required.");
      }
      if (!this.phone) {
        this.errors.push("phone required.");
      }

      if (!this.name) {
        this.errors.push("name required.");
      }
      if (!this.adresse) {
        this.errors.push("adresse required.");
      }
      if (!this.categorie) {
        this.errors.push("categorie required.");
      }
    },
    onSelectChange(data) {
      if (data === "Particulier") {
        document.getElementById("mode_exercice").style.display = "block";
        document.getElementById("specialite").style.display = "block";
        document.getElementById("registre").style.display = "block";
        document.getElementById("delegation").style.display = "block";
        document.getElementById("gouvernerat").style.display = "block";
        document.getElementById("matricule").style.display = "none";
      } else if (data === "Entreprise") {
        document.getElementById("mode_exercice").style.display = "none";
        document.getElementById("specialite").style.display = "none";
        document.getElementById("registre").style.display = "none";
        document.getElementById("matricule").style.display = "block";
        document.getElementById("delegation").style.display = "block";
        document.getElementById("gouvernerat").style.display = "block";
      }
   
    },

   
  },
};
</script>
<style>
.vue-map-container,
.vue-map-container .vue-map {
    width: 100%;
    height: 100%;
}

.pac-target-input {
 
  width: 20%;
   content : "some other placeholder very long" ;
   
  font-size: 1em;
  font-style: italic;

}

.btn {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: rgb(158, 228, 215);
} 
</style>
